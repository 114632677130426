const isEmpty = (value, options) => {
  const defaultOptions = {
    disableCheckZeroNumber: false,
    ...options
  };
  if (value === null || value === void 0 || value === "") {
    return true;
  }
  if (typeof value === "number" && !defaultOptions.disableCheckZeroNumber && value === 0) {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (typeof value === "object" && Object.keys(value).length === 0) {
    return true;
  }
  return false;
};
export { isEmpty };
