<template>
  <div
    ref="selectInputElement"
    class="base-select-input base-select-input__outer"
    :class="[className, { disabled, error: error && !focused, focused }]"
  >
    <div class="base-select-input__wrapper">
      <slot
        :id="selectId"
        name="label"
        :search-query
        :value="modelValue"
      >
        <label
          v-if="label"
          class="base-select-input__label"
          :for="selectId"
        >
          {{ label }}
        </label>
      </slot>

      <div
        ref="selectInputInnerElement"
        class="base-select-input__inner"
      >
        <slot
          :id="selectId"
          name="prefix"
          :search-query
          :value="modelValue"
        />

        <input
          v-if="searchable"
          :id="selectId"
          v-bind="attrs"
          v-model="searchQuery"
          class="base-select-input__element"
          :disabled
          :name
          :placeholder="placeholder ?? label"
          type="search"
          @click="() => openDropdown()"
          @focusin="() => (focused = true)"
          @focusout="() => (focused = false)"
          @keydown.enter.exact="() => handleCreate()"
        />

        <BaseButton2
          v-if="!searchable"
          class="base-select-button"
          @click="() => toggleDropdown()"
        >
          <template v-if="searchQuery">{{ searchQuery }}</template>

          <span
            v-if="!searchQuery"
            class="base-select-button-placeholder"
          >
            {{ placeholder }}
          </span>

          <!-- Кнопка схлопывает высоту, чтобы избежать хардкода, добавляем пробел нулевой ширины -->
          &nbsp;
        </BaseButton2>

        <slot
          :id="selectId"
          name="suffix"
          :search-query
          :value="modelValue"
        />

        <slot
          :id="selectId"
          :handle-clear
          name="clear"
          :search-query
          :value="modelValue"
        >
          <BaseButton2
            v-if="(modelValue || searchQuery) && clearable"
            class="base-select-input__clear-button"
            @click="() => handleClear()"
          >
            <slot
              name="clearIcon"
              :search-query
              :value="modelValue"
            >
              <BaseIcon2 class="base-select-input__clear-icon">
                <path
                  d="m8.92 8 4.89-4.89a.66.66 0 0 0 0-.92.66.66 0 0 0-.92 0L8 7.08 3.11 2.19a.66.66 0 0 0-.92 0 .66.66 0 0 0 0 .92L7.08 8l-4.89 4.89a.66.66 0 0 0 0 .92c.13.13.29.19.46.19.17 0 .33-.06.46-.19L8 8.92l4.89 4.89c.13.13.29.19.46.19.17 0 .33-.06.46-.19a.66.66 0 0 0 0-.92L8.92 8Z"
                  fill="currentColor"
                />
              </BaseIcon2>
            </slot>
          </BaseButton2>
        </slot>

        <slot
          :id="selectId"
          name="arrowButton"
          :search-query
          :value="modelValue"
        >
          <BaseButton2
            class="base-select-open-button"
            :class="{ active: isDropdownOpen }"
            @click="() => toggleDropdown()"
          >
            <slot
              v-if="loading"
              :id="selectId"
              name="loader"
              :search-query
              :value="modelValue"
            >
              <BaseLoader2
                class="base-select-loader"
                :class="classLoader"
              />
            </slot>

            <slot
              v-else
              :id="selectId"
              name="arrowIcon"
              :search-query
              :value="modelValue"
            >
              <BaseIcon2
                class="base-select-open-icon"
                :class="{ active: isDropdownOpen }"
              >
                <path
                  d="M5.18 2.2a.64.64 0 0 0 .03.92L10.4 8l-5.2 4.88a.65.65 0 0 0-.02.92c.25.26.66.27.92.03l5.69-5.35.01-.02.02-.01a.35.35 0 0 0 .08-.13l.05-.07a.69.69 0 0 0 .05-.24.64.64 0 0 0-.05-.24.18.18 0 0 0-.05-.07l-.08-.13-.02-.01-.01-.02L6.1 2.18a.64.64 0 0 0-.92.03V2.2Z"
                  fill="currentColor"
                />
              </BaseIcon2>
            </slot>
          </BaseButton2>
        </slot>
      </div>

      <slot
        :id="selectId"
        name="counter"
        :number-characters
        :search-query
        :value="modelValue"
      >
        <span
          v-if="counter"
          class="base-select-input__counter"
          :class="[{ error: numberCharacters > Number(counter) }]"
        >
          {{ numberCharacters }}/{{ counter }}
        </span>
      </slot>
    </div>

    <div
      v-if="((error || slots.error) && !focused) || hint || slots.hint"
      class="base-select-input__messages"
    >
      <slot
        :id="selectId"
        name="error"
        :search-query
        :value="modelValue"
      >
        <p
          v-if="error && !focused"
          class="base-select-input__error"
        >
          {{ error }}
        </p>
      </slot>

      <slot
        :id="selectId"
        name="hint"
        :search-query
        :value="modelValue"
      >
        <p
          v-if="hint"
          class="base-select-input__hint"
        >
          {{ hint }}
        </p>
      </slot>
    </div>
  </div>

  <Teleport
    v-if="isDropdownOpen"
    :to="dropdownTeleportedToBody ? '#teleports' : (dropdownTeleportTo ?? selectInputElement)"
  >
    <ul
      ref="selectDropdownElement"
      class="base-select-dropdown ui-scrollbar-primary"
      :class="className"
      :style="floatingStyles"
    >
      <template
        v-for="(option, optionIndex) in filteredOptions"
        :key="option.value ?? optionIndex"
      >
        <li
          v-if="!option.hidden"
          class="base-select-dropdown__option"
          :class="{ active: option.value === modelValue, disabled: option?.disabled ?? false  }"
        >
          <slot
            :id="selectId"
            :handle-select
            name="option"
            :option
            :value="modelValue"
          >
            <BaseButton2
              class="base-select-dropdown__option-button"
              @click="() => !option?.disabled && handleSelect(option)"
            >
              <slot
                :id="selectId"
                :handle-select
                name="optionContent"
                :option
                :value="modelValue"
              >
                {{ option.name }}
              </slot>
            </BaseButton2>
          </slot>
        </li>
      </template>

      <li
        v-if="filteredOptions.length === 0 && emptyPlaceholder"
        class="base-select-dropdown__option-button"
      >
        <slot
          :id="selectId"
          name="emptyOption"
          :search-query
          :value="modelValue"
        >
          {{ emptyPlaceholder }}
        </slot>
      </li>

      <li
        v-if="createOption && searchable && searchQuery"
        class="base-select-dropdown__create"
      >
        <slot
          :id="selectId"
          :handle-create
          name="optionCreate"
          :search-query
          :value="searchQuery"
        >
          <BaseButton2
            class="base-select-dropdown__create-button"
            @click="() => handleCreate()"
          >
            {{ searchQuery }}

            <slot
              :id="selectId"
              :handle-create
              name="optionCreateBadge"
              :search-query
              :value="searchQuery"
            >
              <span
                v-if="createOptionBadge"
                class="base-select-dropdown__create-badge"
              >
                {{ createOptionBadge }}
              </span>
            </slot>
          </BaseButton2>
        </slot>
      </li>
    </ul>
  </Teleport>
</template>

<script lang="ts" setup>
// TODO: Уйти от типов инпута
import {
  autoUpdate,
  flip as flipMiddleware,
  inline as inlineMiddleware,
  offset as offsetMiddleware,
  size as sizeMiddleware,
  useFloating,
} from "@floating-ui/vue";

import type { OffsetOptions, Placement } from "@floating-ui/vue";
import type { InputHTMLAttributes } from "vue";

// TODO: Добавить undefined и null
interface Option {
  hidden?: boolean;
  name: number | string;
  value: boolean | number | string;
}

interface Props extends /* @vue-ignore */ InputHTMLAttributes {
  async?: boolean;
  class?: InputHTMLAttributes["class"];
  classLoader?: string;
  clearable?: boolean;
  counter?: string;
  createOption?: boolean;
  createOptionBadge?: string;
  disableClosingByClickOutside?: boolean;
  // disableClosingByWindowResize?: boolean;
  // disableClosingByWindowScroll?: boolean;
  disabled?: boolean;
  dropdownTeleportedToBody?: boolean;
  dropdownTeleportTo?: string;
  // disableFocusingElements?: boolean;
  emptyPlaceholder?: string;
  error?: string;
  hint?: string;
  id?: InputHTMLAttributes["id"];
  label?: string;
  loading?: boolean;
  name: InputHTMLAttributes["name"];
  offset?: OffsetOptions;
  options?: Option[];
  placeholder?: InputHTMLAttributes["placeholder"];
  placement?: Placement;
  searchable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  offset: 0,
  options: () => {
    return [];
  },
  placement: "bottom-start",
});

const emits = defineEmits<{
  clear: [];
  close: [];
  create: [value: Option["value"]];
  open: [];
  search: [query: Option["value"]];
  select: [value: Option["value"]];
}>();

const modelValue = defineModel<Option["value"]>();

defineSlots<{
  arrowButton: [id: InputHTMLAttributes["id"], searchQuery: Option["value"], value: Option["value"]];
  arrowIcon: [id: InputHTMLAttributes["id"], searchQuery: Option["value"], value: Option["value"]];
  clear: [handleClear: () => void, id: InputHTMLAttributes["id"], searchQuery: Option["value"], value: Option["value"]];
  clearIcon: [id: InputHTMLAttributes["id"], searchQuery: Option["value"], value: Option["value"]];
  counter: [
    id: InputHTMLAttributes["id"],
    numberCharacters: number,
    searchQuery: Option["value"],
    value: Option["value"],
  ];
  emptyOption: [id: InputHTMLAttributes["id"], searchQuery: Option["value"], value: Option["value"]];
  error: [id: InputHTMLAttributes["id"], searchQuery: Option["value"], value: Option["value"]];
  hint: [id: InputHTMLAttributes["id"], searchQuery: Option["value"], value: Option["value"]];
  label: [id: InputHTMLAttributes["id"], searchQuery: Option["value"], value: Option["value"]];
  loader: [id: InputHTMLAttributes["id"], searchQuery: Option["value"], value: Option["value"]];
  option: [
    handleSelect: (option: Option) => void,
    id: InputHTMLAttributes["id"],
    option: Option,
    value: Option["value"],
  ];
  optionContent: [
    handleSelect: (option: Option) => void,
    id: InputHTMLAttributes["id"],
    option: Option,
    value: Option["value"],
  ];
  optionCreate: [
    handleCreate: () => void,
    id: InputHTMLAttributes["id"],
    searchQuery: Option["value"],
    value: Option["value"],
  ];
  optionCreateBadge: [
    handleCreate: () => void,
    id: InputHTMLAttributes["id"],
    searchQuery: Option["value"],
    value: Option["value"],
  ];
  prefix: [id: InputHTMLAttributes["id"], searchQuery: Option["value"], value: Option["value"]];
  suffix: [id: InputHTMLAttributes["id"], searchQuery: Option["value"], value: Option["value"]];
}>();

defineOptions({
  inheritAttrs: false,
});

const attrs = useAttrs();
const slots = useSlots();

const {
  async,
  class: className,
  createOption,
  disableClosingByClickOutside,
  // disableClosingByWindowResize,
  // disableClosingByWindowScroll,
  // disableFocusingElements,
  id,
  name,
  offset,
  options,
  placement,
  searchable,
} = toRefs(props);

const filteredOptions = ref<Option[]>(options.value);
const focused = ref(false);
const isDropdownOpen = ref(false);

const searchQuery = ref<Option["value"]>(
  options.value.find((option) => {
    return option.value === modelValue.value;
  })?.name ?? "",
);

const selectDropdownElement = ref<HTMLDivElement | null>(null);
const selectId = id.value ?? name.value;
const selectInputElement = ref<HTMLInputElement | null>(null);
const selectInputInnerElement = ref<HTMLDivElement | null>(null);

const numberCharacters = computed(() => {
  return modelValue.value ? String(searchQuery.value).length : 0;
});

const { floatingStyles } = useFloating(selectInputInnerElement, selectDropdownElement, {
  middleware: [
    flipMiddleware(),
    inlineMiddleware(),
    offsetMiddleware(offset.value),
    sizeMiddleware({
      apply({ elements, rects }) {
        Object.assign(elements.floating.style, {
          width: `${rects.reference.width}px`,
        });
      },
    }),
  ],
  open: isDropdownOpen,
  placement,
  whileElementsMounted: autoUpdate,
});

const closeDropdown = () => {
  isDropdownOpen.value = false;

  // TODO: Раскомментировать, когда добавится управление клавишами в дропдауне
  // if (selectInputElement.value && !disableFocusingElements.value) {
  //  selectInputElement.value.focus();
  // }

  // if (!disableClosingByWindowResize.value) {
  //  window.removeEventListener("resize", closeDropdown);
  // }

  // if (!disableClosingByWindowScroll.value) {
  //  window.removeEventListener("scroll", closeDropdown);
  // }

  nextTick(() => {
    const isEmptyValue = isEmpty(modelValue.value, {
      disableCheckZeroNumber: true,
    });

    if (searchQuery.value && isEmptyValue) {
      searchQuery.value = "";
    }
  });

  emits("close");
};

const openDropdown = () => {
  isDropdownOpen.value = true;

  if (!disableClosingByClickOutside.value) {
    onClickOutside(
      selectDropdownElement,
      () => {
        closeDropdown();
      },
      {
        ignore: [selectInputInnerElement],
      },
    );
  }

  // if (!disableClosingByWindowResize.value) {
  //  window.addEventListener("resize", closeDropdown);
  // }

  // if (!disableClosingByWindowScroll.value) {
  //  window.addEventListener("scroll", closeDropdown);
  // }

  emits("open");
};

const handleClear = () => {
  searchQuery.value = "";
  modelValue.value = "";
  emits("clear");
};

const handleCreate = () => {
  closeDropdown();
  modelValue.value = searchQuery.value;
  emits("create", searchQuery.value);
};

const handleSelect = (option: Option) => {
  searchQuery.value = option.name;
  modelValue.value = option.value;
  closeDropdown();
  emits("select", option.value);
};

const toggleDropdown = () => {
  isDropdownOpen.value ? closeDropdown() : openDropdown();
};

if (searchable.value) {
  watch(searchQuery, async () => {
    if (options.value.length > 0 && searchQuery.value) {
      if (async.value) {
        filteredOptions.value = options.value;
        modelValue.value = null;
      } else {
        const currentValue = String(searchQuery.value).toLowerCase().trim();

        filteredOptions.value = options.value.filter((option) => {
          const optionName = String(option.name).toLowerCase().trim();

          return optionName.includes(currentValue);
        });
      }
    }

    emits("search", searchQuery.value);
  });
}

watch(options, () => {
  if (async) {
    filteredOptions.value = options.value;
  }

  if (!isDropdownOpen.value) {
    filteredOptions.value = options.value;

    searchQuery.value =
      options.value.find((option) => {
        return option.value === modelValue.value;
      })?.name ?? "";
  }
});

watch(modelValue, () => {
  const isEmptyValue = isEmpty(modelValue.value, {
    disableCheckZeroNumber: true,
  });

  if (searchQuery.value && isEmptyValue) {
    searchQuery.value = "";
  }

  const activeOption = options.value.find((option) => {
    return option.value === modelValue.value;
  });

  if (activeOption) {
    searchQuery.value = activeOption.name;
    modelValue.value = activeOption.value;
  }
});

defineExpose({
  selectDropdownElement,
  selectInputInnerElement,
});
</script>

<style scoped>
.base-select-input__outer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.base-select-input__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.base-select-input__inner {
  display: flex;
}

.base-select-input__label {
  display: flex;
}

.base-select-input__element {
  width: 100%;
  margin: 0;
  padding: 0;
  font: inherit;
  letter-spacing: inherit;
  border: none;
  outline: none;
  background-color: transparent;
}

.base-select-input__element:-webkit-autofill,
.base-select-input__element:-webkit-autofill:hover,
.base-select-input__element:-webkit-autofill:focus,
.base-select-input__element:-webkit-autofill:active {
  background-clip: text;
  box-shadow: none;
}

.base-select-input__element::-webkit-search-decoration,
.base-select-input__element::-webkit-search-cancel-button,
.base-select-input__element::-webkit-search-results-button,
.base-select-input__element::-webkit-search-results-decoration {
  display: none;
}

.base-select-button {
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  border: none;
}

.base-select-input__messages {
  display: flex;
  flex-direction: column;
}

.base-select-dropdown {
  z-index: var(--z-index-dropdown);
}

.base-select-dropdown__option.disabled button {
  color: var(--grey-3);
  background-color: var(--grey-7);
  cursor: not-allowed;
}
</style>
